import { routePaths } from "src/routes";

// Function to get the current title based on the current URL
export const getModuleTitle = () => {
  const currentUrl = window.location.pathname;
  // Check the current URL against your predefined routes
  const matchingRoute = Object.entries(routePaths).find(([key, value]) => currentUrl === value);

  return matchingRoute ? matchingRoute[0] : "";
};
