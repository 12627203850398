/**
 * Derives additional roles based on existing roles and a set of subject mappings.
 *
 * @param roles The original roles assigned to a user or entity.
 * @param mappings An object mapping source subjects to arrays of target subjects.
 * @returns An array of roles including both the original and newly derived roles.
 */
export const deriveRolesFromMappings = (roles: string[], mappings: { [key: string]: string[] }) => {
  // Start with the original roles to ensure they are included
  const extendedRoles = [...roles];

  // Loop through each role provided in the mappings
  roles.forEach((role) => {
    Object.entries(mappings).forEach(([sourceRole, targetRoles]) => {
      if (role === sourceRole) {
        // Add each targetRole to the extendedRoles if not already included
        targetRoles.forEach((targetRole) => {
          if (!extendedRoles.includes(targetRole)) {
            extendedRoles.push(targetRole);
          }
        });
      }
    });
  });

  // Return the extended roles including originals and any additional roles from mappings
  return extendedRoles;
};
