import { openDB } from "idb";

let mutationQueueCountCallback: ((count: number) => void) | null = null;

const dbPromise = openDB("speamanalytics", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("queryQueue")) {
      db.createObjectStore("queryQueue");
    }
    if (!db.objectStoreNames.contains("mutationQueue")) {
      db.createObjectStore("mutationQueue", { keyPath: "id", autoIncrement: true });
    }
    if (!db.objectStoreNames.contains("documentCache")) {
      db.createObjectStore("documentCache");
    }
  },
});

export const setMutationQueueCountCallback = (callback: ((count: number) => void) | null) => {
  mutationQueueCountCallback = callback;
};

/**
 * Speichert einen Wert in einem bestimmten Store.
 * @param storeName - Der Name des ObjectStores.
 * @param key - Der Schlüssel, unter dem die Daten gespeichert werden.
 * @param value - Die zu speichernden Daten.
 */
export async function savePlainToStore(storeName: string, key: string, value: any) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);

  try {
    await store.put(value, key);
    await tx.done;
  } catch (error) {
    console.error("Fehler beim Speichern in IndexedDB:", error);
    console.log("Problematische Daten:", value);
    throw error;
  }
}

/**
 * Speichert einen Wert in einem bestimmten Store.
 * @param storeName - Der Name des ObjectStores.
 * @param key - Der Schlüssel, unter dem die Daten gespeichert werden.
 * @param value - Die zu speichernden Daten.
 */
export async function saveToStore(storeName: string, key: string | undefined, value: any) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);

  try {
    const serializableValue = JSON.parse(JSON.stringify(value));
    await store.put(serializableValue, key);
    await tx.done;

    if (storeName === "mutationQueue" && mutationQueueCountCallback) {
      const count = await countAllFromStore("mutationQueue");
      mutationQueueCountCallback(count);
    }
  } catch (error) {
    console.error("Fehler beim Speichern in IndexedDB:", error);
    console.log("Problematische Daten:", value);
    throw error;
  }
}

/**
 * Ruft einen Wert aus einem bestimmten Store ab.
 * @param storeName - Der Name des ObjectStores.
 * @param key - Der Schlüssel der abzurufenden Daten.
 */
export async function getFromStore(storeName: string, key: string) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  return await store.get(key);
}

/**
 * Ruft alle Werte aus einem bestimmten Store ab.
 * @param storeName - Der Name des ObjectStores.
 */
export async function getAllFromStore(storeName: string) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  return await store.getAll();
}

/**
 * Löscht einen Wert aus einem bestimmten Store.
 * @param storeName - Der Name des ObjectStores.
 * @param key - Der Schlüssel der zu löschenden Daten.
 */
export async function deleteFromStore(storeName: string, key: string) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  await store.delete(key);
  await tx.done;

  if (storeName === "mutationQueue" && mutationQueueCountCallback) {
    const count = await countAllFromStore("mutationQueue");
    mutationQueueCountCallback(count);
  }
}

/**
 * Zählt alle Werte in einem bestimmten Store.
 * @param storeName - Der Name des ObjectStores.
 * @returns Die Anzahl der Werte im Store.
 */
export async function countAllFromStore(storeName: string) {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  return await store.count();
}
