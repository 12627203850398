import {
  UiConfigColorKeys,
  UiConfigDocumentKeys,
  UiConfigTypographyKeys,
  UiConfigKeys,
  UiConfigOptionKeys,
} from "../types/UiConfig.type";

export const initialColorUiConfig: UiConfigColorKeys = {
  loginFormBackgroundColor: "#ffffff1a",
  loginButtonBackgroundColor: "#45001dff",
  loginButtonTextColor: "#f7a381",
  primaryColor: "#f53220ff",
  secondaryColor: "#3ec98dff",
};

export const initialImagesUiConfig: UiConfigDocumentKeys = {
  logoDocumentId: undefined,
  loginBackgroundDocumentId: undefined,
  loginOverlayDocumentId: undefined,
};

export const initialOptionsUiConfig: UiConfigOptionKeys = {};

export const initialTypographyUiConfig: UiConfigTypographyKeys = {
  fontSize: "md",
};

export const initialUiConfig: UiConfigKeys = {
  color: initialColorUiConfig,
  document: initialImagesUiConfig,
  option: initialOptionsUiConfig,
  typography: initialTypographyUiConfig,
};
