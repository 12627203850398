import React, { createContext, useContext, useState, useEffect } from "react";
import isOnline from "is-online";
import { onlineManager } from "@tanstack/react-query";
import { OpenAPI } from "src/client";

export const OnlineStatusContext = createContext<boolean>(true);

export const OnlineStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [online, setOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const checkOnlineStatus = async () => {
      const online = await isOnline();
      setOnline(online);
      onlineManager.setOnline(online); // Tanstack React Query
      //@ts-ignore
      OpenAPI.IS_ONLINE = online; // Required for sending requests to idb through client/core/request.ts
    };

    checkOnlineStatus();

    const intervalId = setInterval(checkOnlineStatus, 30000);

    const updateOnlineStatus = () => checkOnlineStatus();
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return <OnlineStatusContext.Provider value={online}>{children}</OnlineStatusContext.Provider>;
};

export const useOnlineStatus = () => {
  return useContext(OnlineStatusContext);
};
