import { extendTheme } from "@chakra-ui/react";

// Global style overrides
import styles from "./styles";

// Foundational style overrides
import colors from "./foundations/colors";

// Custom Element style overrides
import Button from "./components/button";
import Text from "./components/text";
import { darken, lighten } from "@mui/material";
import { createFontSizes, fontSizes } from "./fontSizes";
import { UiConfigKeys } from "../types/UiConfig.type";

const overrides = {
  styles,
  colors,
  fontSizes: fontSizes,
  components: {
    Button,
    Text,
    // Other components go here
  },
};

export const createChakraUiThemeFromUiConfig = (uiConfig: {
  color?: UiConfigKeys["color"];
  typography?: UiConfigKeys["typography"];
}) => {
  const primaryColor = uiConfig?.color?.primaryColor || colors.primary["500"];
  const secondaryColor = uiConfig?.color?.secondaryColor || colors.secondary["500"];

  return extendTheme({
    ...overrides,
    fontSizes: createFontSizes(uiConfig?.typography?.fontSize),
    colors: {
      ...colors,
      primary: {
        50: lighten(primaryColor, 0.9),
        100: lighten(primaryColor, 0.8),
        200: lighten(primaryColor, 0.6),
        300: lighten(primaryColor, 0.4),
        400: lighten(primaryColor, 0.2),
        500: primaryColor,
        600: darken(primaryColor, 0.2),
        650: darken(primaryColor, 0.3),
        700: darken(primaryColor, 0.4),
        800: darken(primaryColor, 0.6),
        900: darken(primaryColor, 0.8),
        950: darken(primaryColor, 0.9),
      },
      secondary: {
        50: lighten(secondaryColor, 0.9),
        100: lighten(secondaryColor, 0.8),
        200: lighten(secondaryColor, 0.6),
        300: lighten(secondaryColor, 0.4),
        400: lighten(secondaryColor, 0.2),
        500: secondaryColor,
        600: darken(secondaryColor, 0.2),
        650: darken(secondaryColor, 0.3),
        700: darken(secondaryColor, 0.4),
        800: darken(secondaryColor, 0.6),
        900: darken(secondaryColor, 0.8),
        950: darken(secondaryColor, 0.9),
      },
    },
  });
};
