import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  EmployeeService,
  NumberCircleService,
  AttributeGroupService,
  AttributeService,
  BranchService,
  IncomeExpenseCategoryService,
  IncomeExpenseService,
  IncomeExpenseRecurringHistoryService,
} from "src/client";
import { useTranslation } from "react-i18next";
import { useOnlineStatus } from "src/contexts/OnlineStatusContext";
import { offlineQueryFn } from "./offlineQueryFn";

export const useGetModuleIdTitle = () => {
  const isOnline = useOnlineStatus();
  const queryClient = useQueryClient();
  const { module_type, id } = getModuleTypeAndId();
  const { t } = useTranslation("id_routes");

  const queryFn = () => {
    switch (module_type) {
      case "number-circles":
        return NumberCircleService.getNumberCircle({ id });
      case "employees":
        return EmployeeService.getEmployee({ id });
      case "attribute-groups":
        return AttributeGroupService.getAttributeGroup({ id });
      case "attributes":
      case "attribute":
        return AttributeService.getAttribute({ id });
      case "branches":
      case "branch":
        return BranchService.getBranch({ id });
      case "income-expense-categories":
      case "income-expense-category":
        return IncomeExpenseCategoryService.getIncomeExpenseCategory({ id });
      case "income-expenses":
      case "income-expense":
        return IncomeExpenseService.getIncomeExpense({ id });
      case "income-expense-recurring-histories":
      case "income-expense-recurring-history":
        return IncomeExpenseRecurringHistoryService.getIncomeExpenseRecurringHistory({ id });
      default:
        return Promise.reject("Invalid module_type");
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [...(!isOnline ? ["Offline"] : []), "ModelType", module_type, id],
    queryFn: isOnline ? queryFn : offlineQueryFn(queryClient),
    enabled: !!module_type && !!id,
  });

  if (isLoading) return t("loading");

  if (error) {
    console.error("Error fetching data:", error);
    return "";
  }

  switch (module_type) {
    case "employees":
    case "number-circles":
    case "branches":
    case "income-expense-categories":
      //@ts-ignore
      return t(module_type) + (data?.name || "");

    case "income-expenses":
    case "income-expense":
      //@ts-ignore
      return t(module_type) + t(`common:type`) + ": " + t(`common:${data?.type}`);

    case "income-expense-recurring-histories":
    case "income-expense-recurring-history":
      //@ts-ignore
      return t(module_type) + t(`common:type`) + ": " + t(`common:${data?.incomeExpense.type}`);

    case "attributes":
    case "attribute":
      return (
        t(module_type) +
        //@ts-ignore
        (data?.systemAttribute && data?.translationKey ? t(`common:${data?.translationKey}`) : data?.name || "")
      );
    default:
      return "";
  }
};

function getModuleTypeAndId() {
  const url = window.location.pathname;
  const segments = url.split("/").filter((segment) => segment !== "");

  if (segments.length < 2) {
    return { module_type: "", id: "" };
  }

  let moduleType = segments[0];
  let id = segments[1];

  return { module_type: moduleType, id: id };
}
