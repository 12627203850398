import { PersistedClient, Persister } from "@tanstack/react-query-persist-client";
import { saveToStore, getFromStore, deleteFromStore } from "./idb";

/**
 * Erstellt einen React Query Persister für IndexedDB.
 * @param storeName - Der Name des ObjectStores in IndexedDB.
 * @param key - Der Schlüssel für die gespeicherten Daten.
 */
export function createIDBPersister(storeName = "queryQueue", key = "react-query-cache"): Persister {
  return {
    persistClient: async (client: PersistedClient) => {
      await saveToStore(storeName, key, client);
    },
    restoreClient: async () => {
      return await getFromStore(storeName, key);
    },
    removeClient: async () => {
      await deleteFromStore(storeName, key);
    },
  };
}
