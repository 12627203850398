import { lazy } from "react";

// Layouts
const InvalidLayout = lazy(() => import("./layouts/InvalidLayout"));

// Pages
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));

// Employee Pages
const EmployeeListPage = lazy(() => import("./pages/Employee/pages/EmployeeListPage"));
const EmployeeDetailsPage = lazy(() => import("./pages/Employee/pages/EmployeeDetailsPage"));

// Customization Pages
const CustomizationGeneralPage = lazy(() => import("./pages/Customization/pages/CustomizationGeneralPage"));
const CustomizationLoginPage = lazy(() => import("./pages/Customization/pages/CustomizationLoginPage"));

const SettingsPage = lazy(() => import("./pages/Settings/pages/SettingsPage"));

// NumberCircle Pages
const NumberCircleListPage = lazy(() => import("./pages/NumberCircle/pages/NumberCircleListPage"));
const NumberCircleDetailsPage = lazy(() => import("./pages/NumberCircle/pages/NumberCircleDetailsPage"));

// AttributeGroup Pages
const AttributeGroupListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeGroupListPage"));
const AttributeGroupDetailsPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeGroupDetailsPage"));

// Grouped Attribute Pages
const GroupedAttributeListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeListPage"));
const GroupedAttributeDetailsPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeDetailsPage"));

// Grouped AttributeModel Pages
const GroupedAttributeModelListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeModelListPage"));

// Attribute Pages
const AttributeListPage = lazy(() => import("./pages/Attribute/pages/AttributeListPage"));
const AttributeDetailsPage = lazy(() => import("./pages/Attribute/pages/AttributeDetailsPage"));

// AttributeModel Pages
const AttributeModelListPage = lazy(() => import("./pages/Attribute/pages/AttributeModelListPage"));

// Branch Pages
const BranchListPage = lazy(() => import("./pages/Branch/pages/BranchListPage"));
const BranchDetailsPage = lazy(() => import("./pages/Branch/pages/BranchDetailsPage"));

// IncomeExpenseCategory Pages
const IncomeExpenseCategoryListPage = lazy(
  () => import("./pages/IncomeExpenseCategory/pages/IncomeExpenseCategoryListPage")
);
const IncomeExpenseCategoryDetailsPage = lazy(
  () => import("./pages/IncomeExpenseCategory/pages/IncomeExpenseCategoryDetailsPage")
);

// IncomeExpense Pages
const IncomeExpenseListPage = lazy(() => import("./pages/IncomeExpense/pages/IncomeExpenseListPage"));
const IncomeExpenseDetailsPage = lazy(() => import("./pages/IncomeExpense/pages/IncomeExpenseDetailsPage"));
const IncomeExpenseDocumentListPage = lazy(() => import("./pages/IncomeExpense/pages/IncomeExpenseDocumentListPage"));

// IncomeExpenseRecurringHistory Pages
const IncomeExpenseRecurringHistoryListPage = lazy(
  () => import("./pages/IncomeExpenseRecurringHistory/pages/IncomeExpenseRecurringHistoryListPage")
);
const IncomeExpenseRecurringHistoryDetailsPage = lazy(
  () => import("./pages/IncomeExpenseRecurringHistory/pages/IncomeExpenseRecurringHistoryDetailsPage")
);
const IncomeExpenseRecurringHistoryDocumentListPage = lazy(
  () => import("./pages/IncomeExpenseRecurringHistory/pages/IncomeExpenseRecurringHistoryDocumentListPage")
);

export const routePaths = {
  home: "/",
  login: "/login",
  employees: "/employees",
  employeeDetails: (employeeId: string) => `/employees/${employeeId}`,
  customizationGeneral: "/customization/general",
  customizationLogin: "/customization/login",
  settings: "/settings",
  numberCircles: "/number-circles",
  numberCircleDetails: (numberCircleId: string) => `/number-circles/${numberCircleId}`,
  attributeGroups: "/attribute-groups",
  attributeGroupDetails: (attributeGroupId: string) => `/attribute-groups/${attributeGroupId}`,
  attributeGroupAttributeList: (attributeGroupId: string) => `/attribute-groups/${attributeGroupId}/attributes`,
  attributeGroupAttributeDetails: (attributeGroupId: string, attributeId: string) =>
    `/attribute-groups/${attributeGroupId}/attributes/${attributeId}`,
  attributeGroupAttributeModelList: (attributeGroupId: string, attributeId: string) =>
    `/attribute-groups/${attributeGroupId}/attributes/${attributeId}/models`,
  attributes: "/attributes",
  attributeDetails: (attributeId: string) => `/attributes/${attributeId}`,
  attributeModelList: (attributeId: string) => `/attributes/${attributeId}/models`,
  branches: "/branches",
  branchDetails: (branchId: string) => `/branches/${branchId}`,
  incomeExpenseCategories: "/income-expense-categories",
  incomeExpenseCategoryDetails: (incomeExpenseCategoryId: string) =>
    `/income-expense-categories/${incomeExpenseCategoryId}`,
  incomeExpenses: "/income-expenses",
  incomeExpenseDetails: (incomeExpenseId: string) => `/income-expenses/${incomeExpenseId}`,
  incomeExpenseDocumentList: (incomeExpenseId: string) => `/income-expenses/${incomeExpenseId}/documents`,
  incomeExpenseRecurringHistories: "/income-expense-recurring-histories",
  incomeExpenseRecurringHistoryDetails: (incomeExpenseRecurringHistoryId: string) =>
    `/income-expense-recurring-histories/${incomeExpenseRecurringHistoryId}`,
  incomeExpenseRecurringHistoryDocumentList: (incomeExpenseRecurringHistoryId: string) =>
    `/income-expense-recurring-histories/${incomeExpenseRecurringHistoryId}/documents`,
};

const publicRoutes = [
  { path: routePaths.login, component: Login, layout: Login, offline: true },
  { path: "*", component: Home, layout: InvalidLayout, offline: true },
];

const privateRoutes = [
  { path: "/", component: Home, offline: true },
  {
    path: routePaths.employees,
    component: EmployeeListPage,
    requiredAbility: [
      ["view", "FE_Employee"],
      ["view", "Employee"],
    ],
  },
  {
    path: routePaths.employeeDetails(":id"),
    component: EmployeeDetailsPage,
    requiredAbility: [
      ["view", "FE_Employee"],
      ["view", "Employee"],
    ],
  },
  {
    path: routePaths.customizationGeneral,
    component: CustomizationGeneralPage,
    requiredAbility: [
      ["view", "FE_Customization"],
      ["view", "Customization"],
      ["view", "PublicAttribute"],
    ],
  },
  {
    path: routePaths.customizationLogin,
    component: CustomizationLoginPage,
    requiredAbility: [
      ["view", "FE_Customization"],
      ["view", "Customization"],
      ["view", "PublicAttribute"],
    ],
  },
  {
    path: routePaths.settings,
    component: SettingsPage,
    requiredAbility: [
      ["view", "FE_Settings"],
      ["view", "Settings"],
    ],
  },
  {
    path: routePaths.numberCircles,
    component: NumberCircleListPage,
    requiredAbility: [
      ["view", "FE_NumberCircle"],
      ["view", "NumberCircle"],
    ],
  },
  {
    path: routePaths.numberCircleDetails(":id"),
    component: NumberCircleDetailsPage,
    requiredAbility: [
      ["view", "FE_NumberCircle"],
      ["view", "NumberCircle"],
    ],
  },
  {
    path: routePaths.attributeGroups,
    component: AttributeGroupListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
    ],
  },
  {
    path: routePaths.attributeGroupDetails(":id"),
    component: AttributeGroupDetailsPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeList(":id"),
    component: GroupedAttributeListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeDetails(":id", ":attributeId"),
    component: GroupedAttributeDetailsPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeModelList(":id", ":attributeId"),
    component: GroupedAttributeModelListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
      ["view", "AttributeModel"],
    ],
  },
  {
    path: routePaths.attributes,
    component: AttributeListPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeDetails(":id"),
    component: AttributeDetailsPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeModelList(":id"),
    component: AttributeModelListPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
      ["view", "AttributeModel"],
    ],
  },
  {
    path: routePaths.branches,
    component: BranchListPage,
    requiredAbility: [
      ["view", "FE_Branch"],
      ["view", "Branch"],
    ],
  },
  {
    path: routePaths.branchDetails(":id"),
    component: BranchDetailsPage,
    requiredAbility: [
      ["view", "FE_Branch"],
      ["view", "Branch"],
    ],
  },
  {
    path: routePaths.incomeExpenseCategories,
    component: IncomeExpenseCategoryListPage,
    requiredAbility: [
      ["view", "FE_IncomeExpenseCategory"],
      ["view", "IncomeExpenseCategory"],
    ],
  },
  {
    path: routePaths.incomeExpenseCategoryDetails(":id"),
    component: IncomeExpenseCategoryDetailsPage,
    requiredAbility: [
      ["view", "FE_IncomeExpenseCategory"],
      ["view", "IncomeExpenseCategory"],
    ],
  },
  {
    path: routePaths.incomeExpenses,
    component: IncomeExpenseListPage,
    requiredAbility: [
      ["view", "FE_IncomeExpense"],
      ["view", "IncomeExpense"],
    ],
  },
  {
    path: routePaths.incomeExpenseDetails(":id"),
    component: IncomeExpenseDetailsPage,
    requiredAbility: [
      ["view", "FE_IncomeExpense"],
      ["view", "IncomeExpense"],
    ],
  },
  {
    path: routePaths.incomeExpenseDocumentList(":id"),
    component: IncomeExpenseDocumentListPage,
    requiredAbility: [
      ["view", "FE_IncomeExpense"],
      ["view", "IncomeExpense"],
      ["view", "Document"],
      ["view", "DocumentParent"],
    ],
  },
  {
    path: routePaths.incomeExpenseRecurringHistories,
    component: IncomeExpenseRecurringHistoryListPage,
    requiredAbility: [
      ["view", "FE_IncomeExpenseRecurringHistory"],
      ["view", "IncomeExpenseRecurringHistory"],
    ],
  },
  {
    path: routePaths.incomeExpenseRecurringHistoryDetails(":id"),
    component: IncomeExpenseRecurringHistoryDetailsPage,
    requiredAbility: [
      ["view", "FE_IncomeExpenseRecurringHistory"],
      ["view", "IncomeExpenseRecurringHistory"],
    ],
  },
  {
    path: routePaths.incomeExpenseRecurringHistoryDocumentList(":id"),
    component: IncomeExpenseRecurringHistoryDocumentListPage,
    requiredAbility: [
      ["view", "FE_IncomeExpenseRecurringHistory"],
      ["view", "IncomeExpenseRecurringHistory"],
      ["view", "Document"],
      ["view", "DocumentParent"],
    ],
  },
];

export { publicRoutes, privateRoutes };
