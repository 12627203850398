import { useEffect, useState } from "react";
import vars from "../config/vars";
import { getCachedDocumentUrl } from "src/contexts/OfflineCacheContext";

export const useDocumentUrl = ({
  document,
  documentId,
  functionType,
  isOnline,
  isPublic = false,
  size,
}: {
  document?: any;
  documentId?: string;
  functionType: "document" | "image" | "imageById";
  isOnline: boolean;
  isPublic?: boolean;
  size?: string;
}) => {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    const updateUrl = async () => {
      if (!document && !documentId) return;

      if (!isOnline) {
        const cachedUrl = await getCachedDocumentUrl(document ? document.id : documentId);
        if (cachedUrl) {
          setUrl(cachedUrl);
        }
        return;
      }

      if (functionType === "document" && document) {
        setUrl(getUrlFromDocument(document));
      } else if (functionType === "image" && document) {
        setUrl(getImageUrlFromDocument(document, size));
      } else if (functionType === "imageById" && documentId) {
        setUrl(getImageUrlFromDocumentId(documentId, isPublic, size));
      }
    };

    updateUrl();
  }, [document, documentId, functionType, isOnline, isPublic, size]);

  return url;
};

export const getUrlFromDocument = (document: any) => {
  if (!document) return "";
  return `${vars.apiUrl}/documents/${document.id}/object`;
};

export const getImageUrlFromDocument = (document: any, size?: string) => {
  if (!document) return "";
  return `${vars.apiUrl}/documents/${document.id}/object${size ? `?size=${size}` : ""}`;
};

export const getImageUrlFromDocumentId = (documentId?: string, isPublic?: boolean, size?: string) => {
  if (!documentId) return "";
  return `${vars.apiUrl}/${isPublic ? "public-documents" : "documents"}/${documentId}/${isPublic ? "image" : "object"}${
    size ? `?size=${size}` : ""
  }`;
};
