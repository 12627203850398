/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { ListResponse } from '../models/ListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountryService {

    /**
     * Get a list of `Country`s
     * @returns any A list of `Countries`.
     * @throws ApiError
     */
    public static listCountries({
page,
size,
filter,
sort,
searchTerm,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
/**
 * Seach Term for full text search. Whether supported and which fields are included depends on the model.
 */
searchTerm?: string,
}): CancelablePromise<ListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/countries',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Create a new `Country`
     * @returns Country A single `Country`.
     * @throws ApiError
     */
    public static createCountry({
requestBody,
}: {
requestBody: Country,
}): CancelablePromise<Country> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/countries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a specific `Country`
     * @returns Country A single `Country`.
     * @throws ApiError
     */
    public static getCountry({
id,
}: {
/**
 * ID of the `Country`
 */
id: string,
}): CancelablePromise<Country> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/countries/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a specific `Country`
     * @returns Country A single `Country`.
     * @throws ApiError
     */
    public static updateCountry({
id,
requestBody,
}: {
/**
 * ID of the `Country`
 */
id: string,
requestBody: Country,
}): CancelablePromise<Country> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/countries/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific `Country`
     * @returns void 
     * @throws ApiError
     */
    public static deleteCountry({
id,
}: {
/**
 * ID of the `Country`
 */
id: string,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/countries/{id}',
            path: {
                'id': id,
            },
        });
    }

}
