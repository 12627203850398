import { createMongoAbility, MongoAbility } from "@casl/ability";
import { roleMappings } from "./roleMappings";
import { deriveRolesFromMappings } from "../../utils/auth/deriveRolesFromMappings";

export type SpeamAction = "view" | "create" | "update" | "delete" | "do";
export type SpeamSubject =
  | "Revision"
  | "Employee"
  | "Country"
  | "Statistics" // count routes
  | "Document"
  | "DocumentParent"
  | "PublicAttribute"
  | "Settings"
  | "CustomField"
  | "NumberCircle"
  | "NumberCircleAssignment"
  | "Entity"
  | "AttributeGroup"
  | "Attribute"
  | "AttributeModel"
  | "AttributeParent"
  | "Branch"
  | "IncomeExpenseCategory"
  | "IncomeExpense"
  | "IncomeExpenseRecurringHistory"
  | "Metabase"
  | "FE_Employee"
  | "FE_Customization"
  | "FE_Settings"
  | "FE_NumberCircle"
  | "FE_AttributeGroup"
  | "FE_Attribute"
  | "FE_AttributeModel"
  | "FE_Branch"
  | "FE_IncomeExpenseCategory"
  | "FE_IncomeExpense"
  | "FE_IncomeExpenseRecurringHistory";
export type SpeamAbility = MongoAbility<[SpeamAction, SpeamSubject]>;

// add additional rules, e.g. to allow selectors to work
//Will auto-allow view on Location and Employee on restricted fields
export const additionalRules = [
  /*
    {
      action: 'view' as SpeamAction,
      subject: 'Location' as SpeamSubject,
      fields: ['id', 'entityType', 'name', 'customerId'],
    },
    {
      action: 'view' as SpeamAction,
      subject: 'Employee' as SpeamSubject,
      fields: ['id', 'entityType', 'name'],
    },
  */
];

export const getUserAbility = (roles: string[]) => {
  // extend roles by adding derived roles (required roles for given roles)
  const extendedRoles = deriveRolesFromMappings(roles, roleMappings);
  const rolePrefix = "APP_SPEAMANALYTICS_";

  const filteredRoles = extendedRoles
    .filter((role) => role.startsWith(rolePrefix))
    .map((role) => role.slice(rolePrefix.length));

  // "positive roles" allow users to do something
  // "inverted roles" deny users to do something
  // example invertedRole syntax:  NOT_VIEW_PRODUCT?SELL_PRICE+CREATED_AT
  // positive roles could be extended similarly, to only allow access to certain fields of an entity
  const [invertedRoles, positiveRoles] = filteredRoles.reduce(
    (acc, role) => {
      if (role.startsWith("NOT")) {
        acc[0].push(role);
      } else {
        acc[1].push(role);
      }
      return acc;
    },
    [[], []] as string[][]
  );

  // transform roles into CASL rules
  const positiveRules = positiveRoles.flatMap((role) => {
    // Skip roles without underscores
    if (!role.includes("_")) {
      return [];
    }

    const roleParts = role.split("_");
    if (roleParts.length < 2) {
      // Optionally, handle error or ignore invalid roles
      console.warn(`Invalid role format: ${role}`);
      return [];
    }

    const action = roleParts.shift()?.toLowerCase() as string; // Safe due to check above

    // Combine the remaining parts for the subject, capitalize the first letter of each, and concatenate
    const subject = roleParts
      .map((part, index) => {
        if (index === 0) {
          return roleParts[0].toUpperCase() === "FE"
            ? part.toUpperCase()
            : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        } else {
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
      })
      .join("");

    const finalSubject = roleParts[0].toUpperCase() === "FE" ? "FE_" + subject.slice(2) : subject;

    return [
      {
        action: action as SpeamAction,
        subject: finalSubject as SpeamSubject,
      },
    ];
  });

  // transform inverted roles into CASL rules
  const invertedRules = invertedRoles.flatMap((role) => {
    const [actionSubject, fields] = role.split("?");

    if (!actionSubject) {
      // Optionally, handle error or ignore invalid roles
      console.warn(`Invalid role format: ${role}`);
      return [];
    }

    const action = actionSubject.split("_")[1].toLowerCase();

    const subject = actionSubject
      .split("_")
      .slice(2)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join("");

    return [
      {
        action: action as SpeamAction,
        subject: subject as SpeamSubject,
        inverted: true,
        fields: fields
          .split("+")
          // SELL_PRICE => sellPrice
          .map((field) => {
            const parts = field.split("_");
            return (
              parts[0].toLowerCase() +
              parts
                .slice(1)
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                .join("")
            );
          }),
      },
    ];
  });

  // inverted roles are always applied last
  return createMongoAbility([...positiveRules, ...additionalRules, ...invertedRules]) as SpeamAbility;
};
