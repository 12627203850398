/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountService {

    /**
     * Get a count for a specific entity
     * @returns any Response with status and date
     * @throws ApiError
     */
    public static countEntity({
entity,
filter,
}: {
/**
 * The entity to count
 */
entity: string,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
}): CancelablePromise<{
/**
 * Number of entities
 */
count?: number;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/count/{entity}',
            path: {
                'entity': entity,
            },
            query: {
                'filter': filter,
            },
        });
    }

}
