import { useToast } from "@chakra-ui/react";

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
      error?: string;
    };
    status?: number;
  };
}

const useSnackError = () => {
  const toast = useToast();

  const snackError = (error: ErrorResponse) => {
    let title = "Error";
    let description = "Unknown error";
    const status: "error" = "error";

    if (error.response?.data?.message) {
      description = error.response.data.message;
      if (error.response?.data?.error) {
        description = error.response.data.error;
      }
    } else if (!error.response?.status) {
      description = "Unknown error";
    } else {
      switch (error.response.status) {
        case 401:
          description = "Missing permission";
          break;
        case 403:
          description = "Action not allowed";
          break;
        case 404:
          description = "Not found";
          break;
        case 413:
          description = "File too large";
          break;
        case 500:
          description = "Internal server error";
          break;
        case 503:
          description = "Service unavailable";
          break;
        default:
          description = "Unknown error";
          break;
      }
    }

    toast({
      title,
      description,
      status,
      position: "bottom",
    });
  };

  return snackError;
};

export default useSnackError;
