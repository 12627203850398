import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StatusService } from "../client";
import { Box, Container, Heading, Image, Flex, Text, Badge, Spinner, Skeleton } from "@chakra-ui/react";
import { useStore } from "../zustand/store";
import { useAuth } from "react-oidc-context";
import { useDocumentUrl } from "../utils/getURLFromDocument";
import { useTranslation } from "react-i18next";
import { getModuleTitle } from "../utils/GetCurrentTitle";
import { useGetModuleIdTitle } from "src/utils/useGetModuleIdTitle";
import { useLocation } from "react-router-dom";
import { getVersion } from "src/utils/getVersion";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useOnlineStatus } from "src/contexts/OnlineStatusContext";
import { offlineQueryFn } from "src/utils/offlineQueryFn";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const isOnline = useOnlineStatus();
  const auth = useAuth();

  const { t } = useTranslation("common");
  const reset = useStore((state) => state.reset);
  const uiConfig = useStore((state) => state.uiConfig);

  const logoDocumentId = uiConfig?.document.logoDocumentId;
  const logoUrl = useDocumentUrl({ documentId: logoDocumentId, functionType: "imageById", isOnline });

  const [currentModuleTitle, setCurrentModuleTitle] = useState("");
  const location = useLocation();

  const moduleIdTitle = useGetModuleIdTitle();
  const queryClient = useQueryClient();

  const statusQuery = useQuery({
    queryKey: [...(!isOnline ? ["Offline"] : []), "get", "Status"],
    queryFn: isOnline ? () => StatusService.getStatus() : offlineQueryFn(queryClient),
  });

  useEffect(() => {
    const isRootModule = getModuleTitle() === "home";
    const title = isRootModule
      ? t("welcomeEmployee", { name: auth?.user?.profile.name })
      : getModuleTitle()
      ? t(getModuleTitle(), { ns: "routes" })
      : moduleIdTitle;

    setCurrentModuleTitle(title);
  }, [moduleIdTitle, t, auth, location.pathname]);

  return (
    <Flex direction="column" minH="100vh">
      <Container maxW="container.xl" flex="1">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <Heading fontWeight="light" size={{ base: "md", md: "xl" }}>
            {currentModuleTitle}
          </Heading>
          <Box>
            <Flex direction="column" alignItems="flex-end">
              <Box display="flex" alignItems="center">
                {logoUrl ? (
                  <Image src={logoUrl} alt="Logo" w="300px" h="75px" objectFit="contain" mb="8px" mt="8px" />
                ) : (
                  <Skeleton height="75px" />
                )}
              </Box>
              <Flex direction="row" alignItems="flex-end">
                <Box display="flex" alignItems="flex-end" ml={4}>
                  <Badge
                    colorScheme={"red"}
                    fontWeight="bold"
                    cursor="pointer"
                    mr={2}
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={() => {
                      reset();
                      auth.signoutRedirect();
                    }}
                  >
                    <Flex alignItems="center">{t("logout")}</Flex>
                  </Badge>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
        {children}
      </Container>
      <Flex
        position="relative"
        bottom={0}
        right={0}
        p={2}
        justify={{ base: "center", md: "flex-end" }}
        alignItems={{ base: "center", md: "flex-start" }}
        gap={4}
      >
        <Flex alignItems="center">
          <InfoOutlineIcon color="gray.500" mr={1} />
          {statusQuery.data ? (
            <Text color="gray.600" fontWeight="light">
              {t("apiVersion")} {statusQuery.data.version}
            </Text>
          ) : (
            <Spinner color="gray.600" size="sm" />
          )}
        </Flex>

        <Flex alignItems="center">
          <InfoOutlineIcon color="gray.500" mr={1} />
          <Text color="gray.600" fontWeight="light">
            {t("appVersion")} {getVersion()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
