/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetabaseService {

    /**
     * Get a specific Metabase Token
     * @returns any The Metabase Token
     * @throws ApiError
     */
    public static getMetabaseToken(): CancelablePromise<{
/**
 * The token of the Metabase
 */
token?: string;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/metabase/token',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
