import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { EmployeeService, OpenAPI } from "../client";
import { usePulse } from "../hooks/usePulse";
import { useAuth } from "react-oidc-context";
import { getUserAbility } from "../config/auth/casl";
import { AbilityContext } from "../contexts/AbilityContext";
import { useStore } from "../zustand/store";
import { useOnlineStatus } from "src/contexts/OnlineStatusContext";
import { ReactNode } from "react";

export default function SecurityLayout({ children }: { children: ReactNode }) {
  usePulse();

  const auth = useAuth();
  const setAccessToken = useStore((state) => state.setAccessToken);
  const accessToken = useStore((state) => state.accessToken);
  const isOnline = useOnlineStatus();

  //@ts-ignore
  const [ability, setAbility] = useState(getUserAbility(auth?.user?.profile?.realm_access?.roles || []));
  const [requestSelf, setRequestSelf] = useState(false);
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    if (
      //@ts-ignore
      auth?.user?.profile?.realm_access?.roles &&
      //@ts-ignore
      Array.isArray(auth?.user?.profile?.realm_access?.roles) &&
      //@ts-ignore
      auth?.user?.profile?.realm_access?.roles.length > 0
    ) {
      //@ts-ignore
      setAbility(getUserAbility(auth?.user?.profile?.realm_access?.roles));
    }
  }, [auth?.user]);

  useEffect(() => {
    OpenAPI.TOKEN = auth?.user?.access_token;
    setAccessToken(auth?.user?.access_token);
    OpenAPI.WITH_CREDENTIALS = true;

    EmployeeService.getSelf()
      .then(() => setRequestSelf(true))
      .catch(() => setRequestSelf(true));
  }, [auth?.user?.access_token, setAccessToken]);

  useEffect(() => {
    if (!isOnline) {
      setWasOffline(true);
    } else if (wasOffline) {
      auth.signinSilent().catch((error) => {
        console.warn("Silent Sign-in nach Offline-Modus fehlgeschlagen:", error);
      });
      setWasOffline(false);
    }
  }, [isOnline, wasOffline, auth]);

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <></>;
  }

  if (auth.isLoading) {
    return <></>;
  }

  if (auth.error && isOnline) {
    return <div>Authentication error {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    if (!requestSelf) {
      return <></>;
    }

    if (
      //@ts-ignore
      !(auth?.user?.profile?.realm_access?.roles || []).includes(process.env.REACT_APP_KEYCLOAK_GENERAL_ACCESS_ROLE)
    ) {
      auth.signoutSilent();
      return <Navigate to="/login?message=access_permission_required" replace />;
    }

    if (!OpenAPI.TOKEN || !accessToken) {
      return <></>;
    }

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  } else {
    return <Navigate to="/login" replace />;
  }
}
