import React, { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Center, Spinner, Box, Progress, Text, VStack, Flex, Portal, Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  Employee,
  EmployeeService,
  ListResponse,
  PublicAttribute,
  PublicAttributeService,
  SettingsService,
  StatusService,
} from "../client";
import { useOnlineStatus } from "./OnlineStatusContext";
import { countAllFromStore, getFromStore, savePlainToStore, setMutationQueueCountCallback } from "src/zustand/idb";
import { useStore } from "src/zustand/store";
import { UiConfigDocumentKeys } from "src/types/UiConfig.type";
import { getImageUrlFromDocumentId } from "src/utils/getURLFromDocument";

/*
When user is offline but comes back online, while loading offline content, after that execute mutationQueue step by step, and only after that show normal frontend
currently there is partially an issue, when switching for ex. in workorders status from "Freigegeben" to "In Bearbeitung" and back to "Freigegeben" the mutation is not "executed"
maybe its because "Freigegeben" is the initial state ?!

getDocumentById ... evtl. umbauen sodass er vorab immer getCachedUrl benutzt ... gucken da wo's verwendet wird..

System hinzufügen soll möglich sein , sycen teilw. möglich machen? also zumindest um die stammdaten reinzuladen ... 

SwitchComponent angucken evtl. list switch erweitern mit entityType ? wird der queryKey iwo genutzt?!
*/

type OfflineCacheContextProps = {
  isOfflineCacheReady: boolean;
};

export const cacheDocument = async (keyId: string, documentUrl: string) => {
  try {
    const response = await fetch(documentUrl, {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch document: ${documentUrl}`);
    }
    const blob = await response.blob();
    await savePlainToStore("documentCache", keyId, blob);
  } catch (error) {
    console.error(`Error caching ${keyId}:`, error);
  }
};

export const getCachedDocumentUrl = async (keyId: string): Promise<string | null> => {
  try {
    const blob = await getFromStore("documentCache", keyId);
    if (blob) {
      return URL.createObjectURL(blob);
    }
    return null;
  } catch (error) {
    console.error(`Error retrieving cached document: ${keyId}:`, error);
    return null;
  }
};

export const OfflineCacheContext = createContext<OfflineCacheContextProps>({ isOfflineCacheReady: false });

export const OfflineCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOfflineCacheReady, setIsOfflineCacheReady] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [queryProgress, setQueryProgress] = useState(0);
  const [documentProgress, setDocumentProgress] = useState(0);
  const [pendingMutations, setPendingMutations] = useState(0);
  const [cacheStatus, setCacheStatus] = useState<{ [key: string]: boolean | undefined }>({});
  const isOnline = useOnlineStatus();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const uiConfig = useStore((state) => state.uiConfig);

  useEffect(() => {
    const keys = Object.keys(uiConfig["document"]) as (keyof UiConfigDocumentKeys)[];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const documentId = uiConfig.document[key];
      if (documentId && isOnline) {
        cacheDocument(documentId, getImageUrlFromDocumentId(documentId));
      }
      setDocumentProgress(((i + 1) / keys.length) * 100);
    }
  }, [uiConfig, isOnline]);

  const offlineQueries: {
    queryKey: string[];
    queryFn: () => Promise<any>;
    associatedQueries?: (entry: any) => { queryKey: string[]; queryFn: () => Promise<any> }[];
  }[] = [
    {
      queryKey: ["Offline", "get", "Status"],
      queryFn: (): Promise<any> => StatusService.getStatus(),
    },
    {
      queryKey: ["Offline", "list", "EmployeeSelf"],
      queryFn: (): Promise<Employee> => EmployeeService.getSelf(),
    },
    {
      queryKey: ["Offline", "list", "PublicAttribute"],
      queryFn: (): Promise<ListResponse & { data?: PublicAttribute[] }> =>
        PublicAttributeService.listPublicAttributes({ size: -1 }),
    },
    {
      queryKey: ["Offline", "list", "Settings"],
      queryFn: (): Promise<ListResponse> => SettingsService.listSettings({ size: -1 }),
    },
  ];

  useEffect(() => {
    const initMutationCount = async () => {
      const count = await countAllFromStore("mutationQueue");
      setPendingMutations(count);
    };
    initMutationCount();

    setMutationQueueCountCallback((count) => {
      setPendingMutations(count);
    });

    return () => {
      setMutationQueueCountCallback(null);
    };
  }, []);

  const batchProcessQueries = async (queries: typeof offlineQueries) => {
    for (let i = 0; i < queries.length; i++) {
      const { queryKey, queryFn, associatedQueries } = queries[i];
      const key = JSON.stringify(queryKey);

      if (cacheStatus[key]) continue;

      try {
        if (isOnline) {
          await new Promise<void>((resolve) =>
            setTimeout(async () => {
              queryClient.invalidateQueries({ queryKey });

              await queryClient.prefetchQuery({
                queryKey,
                queryFn,
                staleTime: Infinity,
                gcTime: Infinity,
                meta: { isOfflineCache: true },
              });

              const result = (await queryClient.getQueryData(queryKey)) as any;

              if (associatedQueries && result.data && Array.isArray(result.data)) {
                //const childQueries = result.data.flatMap((entry: any) => associatedQueries(entry));
                //TODO extend totalQueries by childQueries
                for (const entry of result.data) {
                  const childQueries = associatedQueries(entry);

                  for (const childQuery of childQueries) {
                    try {
                      queryClient.invalidateQueries({ queryKey: childQuery.queryKey });
                      await queryClient.prefetchQuery({
                        queryKey: childQuery.queryKey,
                        queryFn: childQuery.queryFn,
                        staleTime: Infinity,
                        gcTime: Infinity,
                        meta: { isOfflineCache: true },
                      });
                    } catch (error) {
                      console.error(`Error caching associated query ${childQuery.queryKey}:`, error);
                    }
                  }
                }
              }

              setCacheStatus((prev) => ({ ...prev, [key]: true }));

              resolve();
            }, 50)
          );
        } else {
          throw new Error("User is offline. Skipping cache.");
        }
      } catch (error) {
        console.error(`Error caching offline query ${queryKey}:`, error);
        setCacheStatus((prev) => ({ ...prev, [key]: undefined }));
      }

      const completedCount = Object.values(cacheStatus).filter((status) => status === true).length;
      setQueryProgress((completedCount / queries.length) * 100);
    }
  };

  const initialCacheData = async () => {
    await batchProcessQueries(offlineQueries);

    const interval = setInterval(() => {
      if (documentProgress === 100) {
        clearInterval(interval);
        if (Object.values(cacheStatus).every((status) => status === true)) {
          setIsOfflineCacheReady(true);
          setHasLoadedOnce(true);
        }
      }
    }, 100);
  };

  const refetchCacheData = async () => {
    if (!isOnline) {
      console.warn("User is offline. Skipping offline query refetch.");
      return;
    }

    for (const { queryKey, queryFn, associatedQueries } of offlineQueries) {
      const key = JSON.stringify(queryKey);

      try {
        queryClient.invalidateQueries({ queryKey });

        await queryClient.prefetchQuery({
          queryKey,
          queryFn,
          staleTime: Infinity,
          gcTime: Infinity,
          meta: { isOfflineCache: true },
        });

        const result = (await queryClient.getQueryData(queryKey)) as any;

        if (associatedQueries && result.data && Array.isArray(result.data)) {
          for (const entry of result.data) {
            const childQueries = associatedQueries(entry);

            for (const childQuery of childQueries) {
              try {
                queryClient.invalidateQueries({ queryKey: childQuery.queryKey });
                await queryClient.prefetchQuery({
                  queryKey: childQuery.queryKey,
                  queryFn: childQuery.queryFn,
                  staleTime: Infinity,
                  gcTime: Infinity,
                  meta: { isOfflineCache: true },
                });
              } catch (error) {
                console.error(`Error refreshing associated query ${childQuery.queryKey}:`, error);
              }
            }
          }
        }

        setCacheStatus((prev) => ({ ...prev, [key]: true }));
      } catch (error) {
        console.error(`Error refreshing offline query ${queryKey}:`, error);
      }
    }
  };

  useEffect(() => {
    if (!hasLoadedOnce) {
      initialCacheData();
    } else if (isOnline && hasLoadedOnce) {
      const refetchInterval = setInterval(() => {
        refetchCacheData();
      }, 60000); // Refetch every minute

      return () => clearInterval(refetchInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, hasLoadedOnce, isOnline, cacheStatus]);

  if (!isOfflineCacheReady && !hasLoadedOnce && isOnline) {
    return (
      <Center h="100vh" w="100vw" bg="gray.50">
        <VStack spacing={6}>
          <Spinner size="xl" color="teal.500" />
          <Box w="80%">
            <Progress value={(queryProgress + documentProgress) / 2} size="sm" colorScheme="teal" />
          </Box>
          <Text color="gray.600">{t("offlineDataLoading", { ns: "authentication" })}</Text>
        </VStack>
      </Center>
    );
  }

  if (!isOfflineCacheReady && !isOnline && !hasLoadedOnce) {
    return (
      <Flex direction="column" align="center" justify="center" minH="100vh" bg="gray.100" p={4}>
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          {t("offlineHeader", { ns: "authentication" })}
        </Text>
        <Text fontSize="lg">{t("offlineMessageData", { ns: "authentication" })}</Text>
      </Flex>
    );
  }

  return (
    <OfflineCacheContext.Provider value={{ isOfflineCacheReady }}>
      <>
        {!isOnline && (
          <Portal>
            <Box position="fixed" top="10px" right="10px" zIndex="auto">
              <Badge variant="solid" colorScheme="red" fontSize="sm" px={4} py={2} borderRadius="md" boxShadow="lg">
                {t("offlineHeader", { ns: "authentication" })}
                {pendingMutations > 0 && ` (${pendingMutations})`}
              </Badge>
            </Box>
          </Portal>
        )}
        {children}
      </>
    </OfflineCacheContext.Provider>
  );
};

export const useOfflineCache = () => {
  return useContext(OfflineCacheContext);
};
