import { QueryClient, QueryFunctionContext } from "@tanstack/react-query";

export const offlineQueryFn =
  <T>(queryClient: QueryClient) =>
  async (context: QueryFunctionContext): Promise<T> => {
    const cachedQuery = queryClient.getQueryState(context.queryKey);

    if (cachedQuery?.data) {
      return cachedQuery.data as T;
    }

    throw new Error(`No offline data available for queryKey: ${JSON.stringify(context.queryKey)}`);
  };
