// fetch settings from server every 30s
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EmployeeService, PublicAttributeService, SettingsService } from "../client";
import { useEffect } from "react";
import { useStore } from "../zustand/store";
import { isEqual } from "lodash";
import i18n from "i18next";
import { useAuth } from "react-oidc-context";
import { useOnlineStatus } from "src/contexts/OnlineStatusContext";
import { offlineQueryFn } from "src/utils/offlineQueryFn";

export const usePulse = () => {
  const setUiConfig = useStore((state) => state.setUiConfig);
  const uiConfig = useStore((state) => state.uiConfig);
  const settings = useStore((state) => state.settings);
  const setSettings = useStore((state) => state.setSettings);
  const auth = useAuth();
  const isOnline = useOnlineStatus();
  const queryClient = useQueryClient();

  const publicAttributeQuery = useQuery({
    queryKey: [...(!isOnline ? ["Offline"] : []), "list", "PublicAttribute"],
    queryFn: isOnline ? () => PublicAttributeService.listPublicAttributes({ size: -1 }) : offlineQueryFn(queryClient),
    refetchInterval: 30000,
  });

  const settingsQuery = useQuery({
    queryKey: [...(!isOnline ? ["Offline"] : []), "list", "Settings"],
    queryFn: isOnline ? () => SettingsService.listSettings({ size: -1 }) : offlineQueryFn(queryClient),
    refetchInterval: 30000,
  });

  const employeeQuery = useQuery({
    queryKey: [...(!isOnline ? ["Offline"] : []), "list", "EmployeeSelf"],
    queryFn: isOnline ? () => EmployeeService.getSelf() : offlineQueryFn(queryClient),
    enabled: auth.isAuthenticated, // Only fetch if the user is authenticated
    refetchInterval: 30000,
  });

  useEffect(() => {
    //searches for changes and updates them when necessary

    let updates: any = {};

    if (publicAttributeQuery.data) {
      publicAttributeQuery.data?.data.forEach((attribute: any) => {
        let keyType = attribute.keyType as keyof typeof updates;
        if (!updates[keyType]) {
          updates[keyType] = {};
        }
        updates[keyType][attribute.key] = attribute.value;
      });

      if (!isEqual(uiConfig, updates)) {
        setUiConfig(updates);
      }
    }

    //eslint-disable-next-line
  }, [publicAttributeQuery.data, isOnline]);

  useEffect(() => {
    // Searches for changes and updates them when necessary
    let updates: any = {};

    const convertTypes = (value: string) => {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return value;
      }
    };

    if (settingsQuery.data) {
      settingsQuery.data?.data.forEach((setting: any) => {
        updates[setting.key] = convertTypes(setting.value);
      });

      if (!updates.initialSetupDate) {
        updates.initialSetupDate = new Date().toISOString();
      }

      if (!isEqual(settings, updates)) {
        setSettings(updates);
      }
    }

    // eslint-disable-next-line
  }, [settingsQuery.data, isOnline]);

  /*useEffect(() => {
    if (settings.language !== i18n.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings]);*/

  useEffect(() => {
    if (auth.isAuthenticated) {
      // If logged in, use employee language
      if (employeeQuery.data && employeeQuery.data.language !== i18n.language) {
        i18n.changeLanguage(employeeQuery.data.language);
      }
    } else {
      // If not logged in, use settings language
      if (settings.language && settings.language !== i18n.language) {
        i18n.changeLanguage(settings.language);
      }
    }
  }, [settings, auth.isAuthenticated, employeeQuery.data, isOnline]);
};
